/* Comander.css */
@import url(https://fonts.googleapis.com/css?family=Roboto);

body {
  font-family: Roboto, sans-serif;
}

#chart {
  max-width: 650px;
  margin: 35px auto;
}
.comandas-container {
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 1rem;
  padding-top: 1.5rem;
  margin-bottom: 5rem;
}

.cont1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 25%;
  padding-left: 20px;
}

.cont2 {
  border-left: orange solid 0.2rem;
  border-right: orange solid 0.2rem;
width: 75%;

}

.comandas-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.comanda-item {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  height: 50px;
  overflow: scroll;
}

.comanda-details {
  display: flex;
  flex-direction: column;
  border-right: 3px solid #ddd;
}

.comanda-label {
  font-weight: bold;
  margin-right: 10px;
  color: #555;
}

.comanda-value {
  color: #333;
}

.order-items {
  margin-top: 10px;
}

.order-item {
  margin-bottom: 5px;
  color: #555;
}

.BtnComander {
  padding: 0.5rem;
  border-radius: 8px;
  background-color: #2b92d5;
}

.BtnComander:hover {
  background-color: #0897f6;
}

.BtnComander2 {
  padding: 0.5rem;
  border-radius: 8px;
  background-color: yellow;
}

.BtnComander2:hover {
  background-color: rgb(174, 174, 0);
}

.contComandas {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/* Add additional styling as needed */

/* Estilos para el formulario de inicio de sesión */

/*   
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-form label {
    margin-bottom: 5px;
  }
  
  .login-form input {
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .login-form button {
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: #45a049;
  } */
.titleEditor {
  color: orange;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .contComandas {
    flex-direction: column; /* Distribuir en una sola columna */
    align-items: center; /* Centrar los elementos en la columna */
  }
  .cont1,
  .cont3 {
    display: none;
  }



  .comandas-container {
   display: none;
   visibility: hidden;
  }
}

.FacturasCont {
  background-color: grey;
  border: 5px white groove;
  height: 50%;
}

.supSectionFact{
  background-color: lightgray;
  display: flex;
  width: 100%;
  gap: 1rem;
  padding: .2rem;
}