@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&family=Montserrat&family=Mountains+of+Christmas&display=swap');
.btnNav{
    height: 30px;
    color: black; 
    margin-top: 2rem;
}
.infoNavi{
    display: flex;
    background-color: white;
    font-weight: 500;
    border-bottom:whitesmoke 2px solid;
    justify-content: center;
}
.btnMasInfo{
    border: none;
    border-radius: 50%;
    box-shadow: 1px 2px 1px black
}
.textNavid{
    font-family: 'Barlow Condensed', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Mountains of Christmas', cursive;
    font-size: 1rem;
    color: black;
    margin: auto;
}
.noBord{
    border: none;
    background-color: transparent;

    border-radius: 50%;
    padding: 0;
 
}
.noBord> svg{
    fill: yellow;
}

.exit{
    border-radius: 6px;
    background-color: rgb(207, 52, 52);
    color: white;
    padding: 0rem .5rem 0rem .5rem ;
    font-size: 1rem
}




/* Estilos generales de la ventana modal */
.modal {
    display: none; /* Cambiado a 'none' por defecto */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0); /* Fondo inicialmente transparente */
    z-index: 1;
    transition: background-color 0.3s ease; /* Efecto de transición en el fondo */
  }
  
  /* Estilos del contenido de la ventana modal */
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff; /* Color de fondo de la ventana modal */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombra ligera */
    transition: transform 0.3s ease; /* Efecto de transición en la posición */
  }
  
  /* Estilos del botón de cierre (si es necesario) */
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #555;
  }
  
  /* Estilos para el botón que abre la ventana modal */
  .btnNav2 {
    color: white;
    padding: 0.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), 0 7px 13px -3px rgba(0, 0, 0, 0.3), inset 0 -3px 0 rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease; /* Efectos de transición */
  }
  
  /* Efecto de desenfoque en el fondo cuando la ventana modal está abierta */
  .modal-open {
    backdrop-filter: blur(5px);
  }




  
.BtnHoras {
  width: 140px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(to right,#77530a,#ffd277,#77530a,#77530a,#ffd277,#77530a);
  background-size: 250%;
  background-position: left;
  color: #ffd277;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
}

.BtnHoras::before {
  position: absolute;
  content: "Nuestros Horarios";
  color: #ffd277;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  border-radius: 8px;
  transition-duration: 1s;
  background-color: rgba(0, 0, 0, 0.842);
  background-size: 200%;
}

.BtnHoras:hover {
  background-position: right;
  transition-duration: 1s;
}

.BtnHoras:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.BtnHoras:active {
  transform: scale(0.95);
}