
.conteinerLB {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  width: 100%;
}
.conteinerLB2 {
  min-height: 60vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 1rem 0;
}

.titInicio {
  width: 160px;
  height: 188px;
  word-wrap: break-word;
  border-radius: 16px;
  background-color: white;
  margin: 0.3rem;
  color: #295db1;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  font-family: 'Montserrat', sans-serif; 
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.titInicio >img{
  object-fit: cover;
  width: 100%;
  height: 80%;
  border-radius:16px 16px 0 0 ;
  border-bottom: orange solid 2px;
}
.textCat{
  margin: auto;
  padding: 0 0.5rem;
  color: black;
  font-size: 24px;
  font-family: 'Highrise', sans-serif;
letter-spacing: .1rem;
}
.naviLanding {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100px;
  font-size: 1.3rem;  
}
.fullWidth{
  width: 100%;
}
.navi2{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.3rem;
  font-family: 'Montserrat', sans-serif;
  margin-top: 16px;

}



.naviTit2{
display:flex ;
font-weight: 600;
color: whitesmoke;
}
.naviTit3{
  display:flex ;
  font-weight: 600;
  color: orange;
  background-color: #1A1A1A;
  padding: .5rem;
  border: solid white 3px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
.titCasa {
  display: flex;
}


.navLink {
  text-decoration: none;
}

.nMenu {
  color: white;
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.leta1 {
  color: #18a59d;
}
.leta2 {
  color: #edcc27;
}
.leta3 {
  color: #c1373e;
}
.leta4 {
  color: #295db1;
}
a {
  text-decoration: none;
}

.leta1 , .leta2 , .leta3 , .leta4{
font-weight: 800;
}

.rowsCardL {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  gap: 7px;
  margin-bottom: 7px;
  
}



.evImg{
  object-fit: cover;
  object-position: bottom;
}
.eventsL > .titInicio{
  width: 86%;
  margin: auto;
  height: 157px;
}
.especial{
  border: solid goldenrod 1px;
  box-shadow: 0px 0px 8px goldenrod;
}

@media (max-width: 600px) {
  .titInicio:hover {
    color: #d47618;
    border-radius: 20%;
    border: solid white;
  }
}
