.titBag{
  color: #18a59d; 

}
.contBag{

margin-top: 10%;
padding-top: 5%;
display: flex;
border-radius: 20px;
align-items: center;
justify-content: center;
border-radius: 5%;
}
.boxPedido{
display: flex;
flex-direction: column;
align-items: center;
gap: 1rem;
}
.boxPedido1{
display: flex;
flex-direction: column;
align-items: center;
}
.btnBack{
outline: #c1373e double 1px;
background-color: #295db1;
border: none;
border-radius: 20%;
padding-top: 0.3rem;
color: white;
padding: .3rem;
margin-bottom: 1rem;
}

.btnWssp{
border-radius: 6px;
background-color:#03C100 ;
border: solid 10px white;
color: white;
font-family: 'Noto Serif Oriya', serif;
padding-top: 0.15rem;
padding-bottom: 0.15rem;
border-radius: 24px;
border: solid white 2px;
text-align: center;
padding: .5rem;
outline: 1px rgb(90, 90, 90) solid;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.low{
background-color:#1bcc18 ;
margin: 2rem;
}
.warning{
margin-bottom: -5px;
}
.wsspTarj{
display: flex;
margin: auto;
width: 25%;
justify-content: center;
flex-direction: column;
    align-items: center;
}
.wsspTarj label{
font-size: 14px;
white-space: nowrap;
}
.selectP{
border-radius:24px;
padding-top: 0.15rem;
padding-bottom: 0.15rem;
text-align: center;
width: 202px;
}
.redX{border:solid 2px red;}


.backBag{
color: black;
margin-left: 0;
padding-top: 10px;
border-radius: 0 24px 24px 0;
}
.backBag>svg{
margin-bottom: -3px;
} 

.QrBag{
outline: groove #E88A23 10px;
border-radius: 24px;
padding: 1rem;
margin-top: 2rem;

}




@media (max-width: 600px) {

.btnBack{
  outline: #c1373e double 1px;
  background-color: #295db1;
  border: none;
  border-radius: 20%;
  padding-top: 0.3rem;
  color: white;
  padding: .3rem;
  margin-bottom: 1rem;
}
.contBag{
  width: 100%;

  margin-top: 30%;
}
.wsspTarj{
  width: 100%;}
}