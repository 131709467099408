.card-body {
    background-color: antiquewhite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 24px;
    border: solid green 2px;

  }
  
  .row {
    gap: 2rem;
  }
  
  .containerDash {
    display: flex;
    flex-direction: column;
  }
  
  .contCharts {
    display: flex;
    justify-content: center;
    gap: 5%;
    margin-bottom: .5rem;
  }
  
  /* Ajusta el tamaño del contenedor del gráfico */
  #chart {
    width: 350px; /* Ajusta el ancho según sea necesario */
    height: 350px; /* Ajusta la altura según sea necesario */
  }
  
  /* Establece un ancho mínimo para el gráfico */
  .apexcharts-canvas {
    min-width: 300px; /* Establece un ancho mínimo para el gráfico */
    min-height: 300px; /* Establece una altura mínima para el gráfico */
  }
  


  .buttonDash {
    color: #090909;
    padding: .5em .9em;
    font-size: 18px;
    border-radius: 0.5em;
    background: #e8e8e8;
    cursor: pointer;
    outline: 1px solid black;
    transition: all 0.3s;
    width: 90%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    margin-top: 1rem;
    min-height: 60px;
  }
  
  button:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
  }