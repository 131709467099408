.goBag{
    position: sticky;
    bottom: 0px;
    background-color: #1a1a1a;
    padding: 1rem;
    border-top: solid orange 1px;
}
.btnGoBag{
    height: 50px;
    display: flex;
    border-radius: 24px;
    background-color: #E88A23;
    border: outset #E88A23 2px;
}

.pedido{
    margin: auto;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}
.cantidad{
    background-color: white;
    padding: .3rem .7rem .3rem .7rem;
    border-radius: 100%;
    margin-left: 1rem;
    color: black;
}
