@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Sono&display=swap');/*details*/
/* font-family: 'Bebas Neue', cursive;
font-family: 'Sono', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Oriya&display=swap');/*titles*/
/*font-family: 'Noto Serif Oriya', serif;*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/* font-family: 'Montserrat', sans-serif; */

@font-face {
  font-family: 'Highrise';
  src: url('Components/assets/fonts/highrise/HighriseFont-Demo-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}




.App {
  text-align: center;
  /* background-image:url("../src/Components/assets/bgMadre2.png"); */
  min-height: 100vh;
  background-position: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media screen and (max-width: 768px) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .App {
    /* background-image:url("../src/Components/assets/bgMadre2.png"); */
    min-height: 100vh;
    /* background-size: cover;  */
     background-position: unset;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
  
}

/* styles.css */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px;
}
