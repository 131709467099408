.admCont{
  display: flex;
  gap: .5rem;
  justify-content: center;
}

/* style={{padding:"1rem",display:"flex", justifyContent:"center" ,gap:"1rem"}} */
.logoCel{
  display: none;
}


.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}
.dropdown-content {
  display: block; /* Mostrar por defecto */
  position: absolute;
  background-color: aliceblue;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: .5rem;
  border-radius: 8px;
  border: solid 1px black;
box-sizing: border-box;
}

.dropdown-content.hidden {
  display: none; /* Ocultar cuando tenga la clase 'hidden' */
}
.LinkAdmBase{
  display: flex;
justify-content: center;
}

@media (max-width: 768px) {
  .admCont{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
    height: 100vh;
    justify-content: flex-start;
 
    gap: 1rem;
    padding: 1rem;
  }
  .admCont > button{
  width: 90%;
  }
  .admCont > button:hover {
    width: 90%;
    }
.logoCel{
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 20%;
  display: block;
  max-width: 60%;
}
}