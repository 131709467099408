.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo estilo blur */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px); /* Ajusta el valor de blur según sea necesario */
  z-index: 999; /* Asegura que esté en la parte superior */
}

.spinner-container {
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.spinner-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
