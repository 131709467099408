.nameProd {
  color: #375a39;
  margin-bottom: 0;
  margin-top: 0;
  text-align: left;
  white-space: nowrap;
  font-size: 1.2rem;
  font-weight: 600;
}
.nameProdEdit {
  font-size: 1rem;
}
.detProd {
  color: black;
  text-align: left;
  margin-bottom: 0;
  margin-top: 0rem;
  font-size: 14px;
  text-align: left;
}
.rightInfo {
  color: black;
  font-family: "Montserrat", sans-serif;
  text-align: end;
  padding-right: 0.5rem;
  text-align: center;
  display: flex;
}
.divBtnProd {
  width: 90px;
  height: 24px;
  align-items: center;
  display: flex;
}
.btnPlus {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  outline: outset green 2px;
  font-family: "Montserrat", sans-serif;
  color: green;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btnPlus:active {
  background-color: #e88a23;
}

.leftInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2rem;
  gap: 1rem;
}
.masMenos {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 16px;
  background-color: #e88a23;
  border: outset #e88a23 2px;
  width: 100%;
  height: 100%;
}
.masMenos:hover {
  border: inset #e88a23 2px;
}

.masMenos > button {
  border: none;
  background-color: transparent;
  color: white;
  font-weight: 600;
  font-size: 1rem;
}
.cuantiti {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: white;
  padding: 0.3rem 0.7rem 0.3rem 0.7rem;
  border-radius: 100%;
}
.CardCont {
  background-color: whitesmoke;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 0px 0px 8px #fdab97;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 4px;

}
.contCard {
  align-content: flex-start;
  align-items: center;
  background-color: whitesmoke;
  display: flex;
  justify-content: space-between;
  margin-top: .25rem;
  gap: .5rem;
}
.contCardEdit {
  align-items: center;
  background-color: whitesmoke;
  display: flex;
  justify-content: flex-start;
  text-wrap: nowrap;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  overflow: hidden;
}
.price {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: rgb(107, 48, 48);
  display:flex;
  flex-direction: column;
  gap: .2rem;
align-items: center;

}
.btn-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.btn-svg svg {
  width: 100%;
  height: 100%;
}
.svg-minus,
.svg-plus {
  width: 100%;
  height: auto;
}
/* Estilos para el iPhone 11 Pro */
@media (max-width: 375px) {
  .btn-svg {
    width: 10px;
    height: 10px;
    margin-right: 0px;
  }
}

/* +----------------------------------- CARD EDIT ------------------------------------+ */

.contCardEdit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  
}

.cardContentEdit {
  flex-grow: 1;

}

.nameProdEdit {
  font-weight: bold;
}

.priceEdit {
  margin-top: 2px;
  margin-bottom: 1px;
}

.divBtnProd {
  margin-left: 10px;
}



.toggle-switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 40px;
  cursor: pointer;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: background-color 0.3s ease-in-out;
}

.toggle-switch-handle {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background {
  background-color: #05c46b;
  box-shadow: inset 0 0 0 2px #04b360;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background .toggle-switch-handle {
  transform: translateX(40px);
}


/* ------------------------------------------ Check box ----------------------------------------- */
.heart-container {
  --heart-color: rgb(189, 91, 255);
  position: relative;
  width: 24px;
  height: 24px;
  transition: .3s;
}

.heart-container .checkbox {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.heart-container .svg-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart-container .svg-outline,
        .heart-container .svg-filled {
  fill: #3d5c3c;
  position: absolute;
}

.heart-container .svg-filled {
  animation: keyframes-svg-filled 1s;
  display: none;
}

.heart-container .svg-celebrate {
  position: absolute;
  animation: keyframes-svg-celebrate .5s;
  animation-fill-mode: forwards;
  display: none;
  stroke: #3d5c3c;
  fill: #3d5c3c;
  stroke-width: 2px;
}

.heart-container .checkbox:checked~.svg-container .svg-filled {
  display: block
}

.heart-container .checkbox:checked~.svg-container .svg-celebrate {
  display: block
}

@keyframes keyframes-svg-filled {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
    filter: brightness(1.5);
  }
}

@keyframes keyframes-svg-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
    display: none;
  }
}