.Formix2 {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.Formix {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: black;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.divCajaBlack{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Formix,.Formix2 label {
  display: block;
  margin-bottom: 5px;
}
.nombreProveedor,.titFormix {
  color: black;
}

.Formix,.Formix2 input[type="number"],
.Formix,.Formix2 input[type="text"],
.Formix,.Formix2 select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}


.Formix,.Formix2 select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23353a40' d='M10 12l-7-5h14l-7 5zm0 3l-7-5h14l-7 5zm0-6l-7-5h14l-7 5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 14px;

}

.Formix,.Formix2 button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.Formix,.Formix2 button[type="submit"]:hover {
  background-color: #0056b3;
}

.Formix{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
  background-color: white;
}


.Formix1 {
  background-color: black;
  color: #00ff00; /* verde */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5); /* sombra verde */
}

.divCajaBlack {
  margin-bottom: 20px;
  justify-content: space-around
}

.labelCaja {
  display: flex;
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 800;
  min-width: 30%;
  font-family: 'Orbitron';
  white-space: nowrap;
}
.labelCaja1{
  padding-left: 2rem;
}

.Finput[type="number"],
.Finput[type="text"],
.Fselect {
  background-color: black;
  color: #00ff00;

  border-radius: 5px;
  padding: 8px;
  width: 50%;
}
.Finput[type="text"],
.Fselect { border: 1px solid #00ff00;}


.Finput[type="number"]::placeholder,
.Finput[type="text"]::placeholder,
select::placeholder {
  color: #00ff00;
}

.Venta {
  background-color: #ffeb3b; /* amarillo */
  color: #333; /* color de texto */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase; /* texto en mayúsculas */
  box-shadow: 0px 3px 0px #c8a415; /* sombra */
  transition: background-color 0.3s, box-shadow 0.3s; /* transición suave */
}

.Venta:hover {
  background-color: #ffc107; /* amarillo más oscuro al pasar el mouse */
  box-shadow: 0px 3px 0px #b28e0d; /* sombra más oscura al pasar el mouse */
}

.Venta:active {
  background-color: #ffa000; /* amarillo al hacer clic */
  box-shadow: none; /* sin sombra al hacer clic */
}

.orderFail,
.orderExito {
  margin-left: 10px;
}


.inputWrapper {
  position: relative;
  width: fit-content;
}

.inputOrbitron {
  font-family: 'Orbitron', sans-serif;
  border: none;
  outline: none;
  font-size: 36px;
  background-color: transparent;
  width: 100px; /* ajusta el ancho según sea necesario */
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-family: 'Orbitron', sans-serif;
  font-size: 20px; /* ajusta el tamaño de fuente según sea necesario */
  color: #666; /* color de texto gris */
  pointer-events: none; /* evita que el span interfiera con el input */
  transition: opacity 0.3s; /* transición suave para la opacidad */
}

.inputOrbitron:focus + .placeholder {
  opacity: 0; /* oculta el placeholder cuando el input está enfocado */
}

